<template>
  <div class="mt-6 sm:mt-5">
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label
        :for="name"
        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
      >
        {{label}}
      </label>

      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="relative  rounded-md shadow-sm">
          <div class="relative">
            <input
              @input="searchOptions"
              :id="name"
              :value="inputValue"
              :name="name"
              :dusk="dusk"
              class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            >

            <div v-if="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
          </div>
          <div
            v-show="filteredOptions.length"
            class="absolute mt-1 w-full rounded-md bg-white shadow-lg"
          >
            <ul
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              class="max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black/5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >

              <li
                v-for="option in filteredOptions"
                :key="option"
                @click="selectOption(option)"
                @mouseover="highlight = option"
                @mouseout="highlight = null"
                v-bind:class="highlight === option ? 'text-white bg-indigo-600' : 'text-gray-900'"
                :id="`hint-${option}`"
                :dusk="`hint-${option}`"
                role="option"
                class="cursor-pointer select-none relative py-2 pl-3 pr-9"
              >
                <div class="space-x-2">
                  <div class="font-normal">
                    {{option}}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <p v-if="error" class="mt-2 text-sm text-red-600">{{error}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    options:{
      type:Array,
      default:[],
    },
    name:{
      type:String,
      default:''
    },
    label:{
      type:String,
      default:''
    },
    dusk:{
      type:String,
      default:''
    },
    value:{
      type:String,
      default:''
    },
    error:{
      type:String,
      default:''
    }
  },

  data(){
    return {
      highlight:null,
      filteredOptions:[],
      inputValue: this.value,
    }
  },

  methods:{
    searchOptions(e){
      let value = e.currentTarget.value;
      let regex = new RegExp(`^${value}`,'i');
      let results = this.options
        .filter(option => value && option.match(regex) && option !== value);

      this.filteredOptions = results;
      this.inputValue = value;
    },
    selectOption(option){
      this.inputValue = option;
      this.filteredOptions = [];
    }
  }

}
</script>
