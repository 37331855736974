<template>
  <div>
    <video
      ref="videoPlayer"
      class="video-js vjs-theme-sea"
    ></video>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  props: {
    sourceFile: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      player: null,
    }
  },
  mounted() {
    console.log(this.sourceFile.url);
    this.player = videojs(
      this.$refs.videoPlayer,
      {
        controls:true,
        sources: [
          {
            src: this.sourceFile.original_url || this.sourceFile.url,
          }
        ],
        poster: this.sourceFile.preview_url || this.sourceFile.preview,
        preload:'auto',
        experimentalSvgIcons:true,
        fluid:true,
        inactivityTimeout: 0,
      }
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>
