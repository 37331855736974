export default [
  {
    id: 'Montserrat',
    name: '<span style="font-family:Montserrat;">Montserrat</span>',
    value: 'Montserrat'
  },
  {
    id: 'Nunito',
    name: '<span style="font-family:Nunito;">Nunito</span>',
    value: 'Nunito'
  },
  {
    id: 'OpenSans',
    name: '<span style="font-family:OpenSans;">OpenSans</span>',
    value: 'OpenSans'
  },
  {
    id: 'Oswald',
    name: '<span style="font-family:Oswald;">Oswald</span>',
    value: 'Oswald'
  },
  {
    id: 'Raleway',
    name: '<span style="font-family:Raleway;">Raleway</span>',
    value: 'Raleway'
  },
  {
    id: 'Roboto',
    name: '<span style="font-family:Roboto;">Roboto</span>',
    value: 'Roboto'
  },
  {
    id: 'RobotoSlab',
    name: '<span style="font-family:RobotoSlab;">RobotoSlab</span>',
    value: 'RobotoSlab'
  },
  {
    id: 'SmoochSans',
    name: '<span style="font-family:SmoochSans;">SmoochSans</span>',
    value: 'SmoochSans'
  },
]
