<template>
  <div class="relative">
    <!-- <MinimalButton
      @click="isOpen = !isOpen"
      dusk="filters"
      class="absolute left-0"
      title="Filter Positions"
    >
      <FontAwesomeIcon
        :icon="faFilterList"
        class="h-4 text-blue-500 hover:text-blue-700"
      />
    </MinimalButton> -->

    <MinimalButton
      v-if="isOpen"
      @click="clearFilters"
      dusk="clear"
      class="absolute right-0"
      title="Clear Filters"
    >
      <FontAwesomeIcon
        :icon="faFilterCircleXmark"
        class="h-4 text-red-500 hover:text-red-700"
      />
    </MinimalButton>

    <div
      class="border border-gray-200 pt-10 pb-4 px-4 rounded shadow-md"
      v-if="isOpen"
    >
      <input
        @keypress.enter="submit"
        v-model="searchRef"
        type="text"
        dusk="search"
        class="border border-gray-200 rounded w-full mb-4"
        placeholder="Search"
        aria-label="Search Here"
      />

      <!-- <label class="text-xs text-center block">
        Base Salary Range
      </label> -->

      <!-- <RangeSliderDual
        :min-value="minCompRef"
        :max-value="maxCompRef"
        @update="updateComps"
        min-name="min_comp"
        max-name="max_comp"
        :min="compMin"
        :max="compMax"
        :step="compStep"
        :dont-send-min-max="true"
      /> -->

      <!-- <MinimalButton
        @click="submit"
        dusk="submit"
      >
        Search Candidates
      </MinimalButton> -->
    </div>

  </div>

</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue';
import MinimalButton from './MinimalButton.vue';
import RangeSliderDual from './RangeSliderDual.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFilterList, faFilterCircleXmark } from '@fortawesome/pro-regular-svg-icons';


const props = defineProps({
    inputs: {
      type: Object,
      required: true,
    },
});

const compMin = 50000;
const compMax = 500000;
const compStep = 10000;

const isOpen = ref(true);

const searchRef = ref(props.inputs.search);
const minCompRef = ref(props.inputs.min_comp || compMin);
const maxCompRef = ref(props.inputs.max_comp || compMax);

onMounted(() => {
  if(props.inputs.search
    || props.inputs.min_comp
    || props.inputs.max_comp
  ) {
    isOpen.value = true;
  }
});

function updateComps(min, max) {
  minCompRef.value = min;
  maxCompRef.value = max;
}

function clearFilters() {
  searchRef.value = '';
  minCompRef.value = compMin;
  maxCompRef.value = compMax;
  submit();
}

function submit() {
  const searchParams = new URLSearchParams();
  if(searchRef.value) {
    searchParams.set('search', searchRef.value);
  }
  if(typeof props.inputs.archived !== 'undefined') {
    searchParams.set('archived', true);
  }

  // if(minCompRef.value !== compMin) {
  //   searchParams.set('min_comp', minCompRef.value);
  // }
  // if(maxCompRef.value !== compMax) {
  //   searchParams.set('max_comp', maxCompRef.value);
  // }
  window.location.search = searchParams.toString();
}

</script>
