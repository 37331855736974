<template>

<div>
  <div
    v-if="notification.type === 'missed-messages'"
    class="p-2 border-b border-gray-200"
  >
    <a
      :href="missedMessagesLink"
      class="block px-4 py-2 text-gray-900 hover:bg-gray-200"
    >
      {{ missedMessagesText }}
    </a>
  </div>

</div>

</template>

<script setup>
import { defineProps, ref, computed, onMounted } from 'vue';


const missedMessagesLink = computed(() => {
  const {recipient} = props.notification.data;
  if(props.user.uuid === recipient.uuid){
    return `/`;
  } else {
    return `/${recipient.type}s/${recipient.id}`;
  }
});

const missedMessagesText = computed(() => {
  const {recipient, missedMessagesCount} = props.notification.data;
  if(props.user.uuid === recipient.uuid){
    return `You have ${missedMessagesCount} new messages`;
  } else {
    return `You have ${missedMessagesCount} missed messages with ${recipient.name}`;
  }
});


const props = defineProps({
  notification: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true
  }
})

</script>
