<template>
  <div
    ref="titleSlideContainer"
    class="relative w-full pointer-events-none"
  >
    <div
      ref="titleSlide"
      class="absolute inset-0 w-full h-full"
      v-html="this.html"
    >
    </div>
  </div>

</template>

<script>
  export default {
    props: {
      sourceFile: {
        type: Object,
        required: true,
      },
    },
    computed: {
      html(){
        let css = this.sourceFile.html.css;
        let tdRegex = /td\s*{([^}]*)}/g;
        let td = tdRegex.exec(css)[1];
        let tableRegex = /table\s*{([^}]*)}/g;
        let table = tableRegex.exec(css)[1];
        let pRegex = /\.editor-block\s*{([^}]*)}/g;
        let p = pRegex.exec(css)[1];

        let html = `<table><tr><td>${this.sourceFile.html.html}</td></tr></table>`;

        html = html.replace(/<td/g, `<td style="${td}"`);
        html = html.replace(/<table/g, `<table style="${table}"`);
        html = html.replace(/<p/g, `<p style="${p}"`);
        return html;
      }
    },
    mounted() {
      this.resizeTitleSlide();
    },
    methods: {
      resizeTitleSlide(){
        if(!this.$refs.titleSlideContainer) return;
        let containerWidth = this.$refs.titleSlideContainer.getBoundingClientRect().width;
        let scale = containerWidth / 1920;
        this.$refs.titleSlide.style.transform = `scale(${scale})`;
        this.$refs.titleSlide.style.transformOrigin = 'top left';
        this.$refs.titleSlideContainer.style.height = `${containerWidth * 0.5625}px`;
      },
    }
  }
</script>
