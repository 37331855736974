<template>
  <div
    class="rounded-xl bg-gray-300 pl-4 pr-2 m-2 relative"
    :class="{
      'pr-4': participant.uuid !== user.uuid
    }"
  >
    <div
      v-if="isOnline"
      class="h-2 w-2 rounded-full bg-green-500 absolute top-0 left-0"
      title="Online"
    ></div>

    <span class="text center">{{ participant.name }}</span>
    <button>
      <FontAwesomeIcon
        v-if="canOptOut"
        @click="emit('onOptOut')"
        :icon="faCircleXmark"
        class="text-red-500 hover:text-gray-400 ml-1"
        title="Remove me from this thread"
      />
    </button>
  </div>

</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, computed} from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-thin-svg-icons';

const emit = defineEmits(['onOptOut']);

const store = useStore();

const props = defineProps({
  participant: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true
  },
  canOptOut: {
    type: Boolean,
    default: false
  }
});

const isOnline = computed(() => {
  return store.state.onlineUsers
    .some(user => user.uuid === props.participant.uuid);
});

</script>
