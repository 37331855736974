<template>
  <div
    v-if="open"
    class="z-50 fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
  >
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->

    <div
      v-if="open"
      v-on:click="cancel"
      class="fixed inset-0 transition-opacity"
    >
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div
      v-if="open"
      class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full"
      :class="[this.fullWidth ? 'sm:max-w-full' : 'sm:max-w-lg']"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <slot name="content"></slot>
        <div class="sm:flex sm:items-start">
          <div v-if="this.danger" class="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              {{title}}
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500 whitespace-normal">
                <slot></slot>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            v-if="!this.hideConfirm"
            v-on:click="confirm"
            dusk="confirm"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            {{confirmText}}
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            v-if="!this.hideCancel"
            v-on:click="cancel"
            type="button"
            dusk="cancel"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            {{cancelText}}
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        props:{
          title:{
            type:String,
            default:'',
          },
          danger:{
            type:Boolean,
            default:false,
          },
          confirmText:{
            type:String,
            default:'Confirm',
          },
          cancelText:{
            type:String,
            default:'Cancel',
          },
          hideConfirm:{
            type:Boolean,
            default:false,
          },
          hideCancel:{
            type:Boolean,
            default:false,
          },
          open:Boolean,
          fullWidth:{
            type:Boolean,
            default:false,
          },
        },
        methods: {
          confirm(event){
            this.$emit('confirm')
          },
          cancel(){
            this.$emit('cancel');
          }
        }
    }
</script>
