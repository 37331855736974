<template>
  <li class="relative col-span-1 flex flex-col justify-between text-center bg-white rounded-lg shadow">
    <ArchiveBoxXMarkIcon
      v-if="candidate.deleted_at"
      class="w-full h-full text-rose-50 absolute z-0"
    />

    <span
      v-if="candidate.current_position"
      class="w-12 h-12 text-green-100 absolute z-10 cursor-pointer"
      :title="`Hired: ${candidate.current_position.name}`"
      >
      <BriefcaseIcon/>
    </span>
    <span
      v-else-if="candidate.business_ready"
      class="w-16 h-16 text-green-100 absolute z-10 cursor-pointer"
      title="Business Ready"
    >
      <CheckBadgeIcon/>
    </span>

    <div class="relative z-1 flex flex-col justify-between h-full">
      <div class="flex-1 flex flex-col p-2 relative">
        <div class="relative mx-auto inline-block">
          <div
            v-if="isOnline"
            class="absolute top-2 right-1 -mt-2 -mr-2 w-3 h-3 bg-green-500 rounded-full"
            title="Online"
          ></div>
          <img
            :src="candidate.headshot.avatar"
            alt="headshot"
            class="w-16 h-16 shrink-0 rounded-full"
          >
        </div>
        <div class="mt-1">
          <div v-if="candidate.files.length">
            <div class="shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs leading-4 font-medium bg-green-100 rounded-full">
              {{candidate.files.length}} Files
            </div>
          </div>
          <div v-else >&nbsp;</div>
        </div>
        <h3 class="mt-1 text-gray-900 text-sm leading-5 font-medium">
          {{candidate.name}}
        </h3>
        <dl class="mt-1 grow flex flex-col justify-between">
          <dt class="sr-only">
            Email
          </dt>
          <dd
            class="text-gray-500 text-sm leading-5 truncate"
            :title="candidate.email"
          >
            {{candidate.email}}
          </dd>
          <dt class="sr-only">
            Phone
          </dt>
          <dd class="mt-3">
            {{candidate.phone}}
          </dd>
        </dl>
      </div>
      <div class="text-xs px-8 p-2">
        <slot name="extras"></slot>
      </div>
      <div class="border-t border-gray-200">
        <slot></slot>
      </div>
    </div>
  </li>
</template>

<script setup>
import {defineProps, computed} from 'vue';
import {useStore} from 'vuex';

import {
  ArchiveBoxXMarkIcon,
  CheckBadgeIcon,
  BriefcaseIcon,
} from '@heroicons/vue/24/outline';

const props = defineProps({
  candidate: {
    type: Object,
    required: true
  }
});

const store = useStore();

const isOnline = computed(() => {
  return store.state.onlineUsers
    .some(user => user.uuid === props.candidate.uuid);
});

</script>
