<template>
  <div>
    <select-single
      v-if="!defaultTeamSelector"
      @single-select="onClientSelect"
      :options="sortedClients"
      :selected-option="mySelectedClient"
      name="client_id"
      dusk="client"
      label="For Client"
      :error="clientError"
      placeholder="Choose a Client"
      :disabled="search.id"
    />

    <select-multiple
      :options="sortedManagers"
      :selected-options="mySelectedManagers"
      name="managers[]"
      dusk="managers"
      :label="defaultTeamSelector ? 'Default Hiring Team' : 'Client Hiring Team'"
      :error="managersError"
    />

    <select-multiple
      :options="sortedRecruiters"
      :selected-options="mySelectedRecruiters"
      name="recruiters[]"
      dusk="recruiters"
      :label="defaultTeamSelector ? 'Default Recruiting Team' : 'Recruiting Team'"
      :error="recruitersError"
    />

    <select-multiple
      :options="sortedAdmins"
      :selected-options="mySelectedAdmins"
      name="admins[]"
      dusk="admins"
      :label="defaultTeamSelector ? 'Default Admin Team' : 'Admin Team'"
      :error="adminsError"
    />
  </div>
</template>

<script setup>
import SelectSingle from './SelectSingle.vue';
import SelectMultiple from './SelectMultiple.vue';
import { ref, computed, defineProps, onMounted, nextTick } from 'vue';

const props = defineProps({
  defaultTeamSelector:{
    type: Boolean,
    default: false
  },
  search:{
    type: Object,
  },
  clients:{
    type: Array,
  },
  recruiters:{
    type: Array,
  },
  admins:{
    type: Array,
  },
  selectedClient:{
    type: Object,
  },
  selectedManagers:{
    type: Array,
    default: [],
  },
  selectedRecruiters:{
    type: Array,
    default: [],
  },
  selectedAdmins:{
    type: Array,
    default: [],
  },
  oldInput: {
    type: Object,
    default: {},
  },
  clientError:{
    type: String,
    default: ''
  },
  managersError:{
    type: String,
    default: ''
  },
  adminsError:{
    type: String,
    default: ''
  },
});

const mySelectedClient = ref(props.selectedClient);
const mySelectedManagers = ref(props.selectedManagers);
const mySelectedAdmins = ref(props.selectedAdmins);
const mySelectedRecruiters = ref(props.selectedRecruiters);

onMounted(()=>{
  if(props.oldInput.client_id){
    const oldClient = props.clients
          .find(client => parseInt(client.id) === parseInt(props.oldInput.client_id));
    mySelectedClient.value = oldClient;
  }

  if(props.oldInput.managers){
    const oldManagers = props.oldInput.managers.map(id => {
      return mySelectedClient.value.managers
        .find(manager => parseInt(manager.id) === parseInt(id));
    });
    mySelectedManagers.value = oldManagers;
  }

  if(props.oldInput.admins){
    const oldAdmins = props.oldInput.admins.map(id => {
      return props.admins
        .find(admin => parseInt(admin.id) === parseInt(id));
    });
    mySelectedAdmins.value = oldAdmins;
  }

  if(props.oldInput.recruiters){
    const oldRecruiters = props.oldInput.recruiters.map(id => {
      return props.recruiters
        .find(recruiter => parseInt(recruiter.id) === parseInt(id));
    });
    mySelectedRecruiters.value = oldRecruiters;
  }
});

function onClientSelect(client){
  mySelectedClient.value = client;
  mySelectedManagers.value = client.default_managers;
  mySelectedRecruiters.value = client.default_recruiters;
  mySelectedAdmins.value = client.default_admins;
}


const sortedClients = computed(() => {
  return props.clients.sort((a, b) => a.name.localeCompare(b.name));
});

const sortedManagers = computed(() => {
  return mySelectedClient.value?.managers?.sort((a, b) => a.last_name.localeCompare(b.last_name));
});

const sortedRecruiters = computed(() => {
  return props.recruiters.sort((a, b) => a.last_name.localeCompare(b.last_name));
});

const sortedAdmins = computed(() => {
  return props.admins.sort((a, b) => a.last_name.localeCompare(b.last_name));
});

</script>
