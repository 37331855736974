<template>
  <div
    class="relative inline-block mx-1 bg-gray-300"
    :style="this.style"
  >

    <div
      v-if="this.clip.type === 'html'"
      class="group mx-auto block h-full overflow-hidden rounded-lg bg-gray-100"
      style="width: 45px;"
    >
      <TitleSlide :sourceFile="this.clip.sourceFile" />
    </div>

    <button
      type="button"
      class="p-0 absolute top-0 right-0 w-4 h-4 rounded-full text-red-600 hover:text-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      @click="this.$emit('remove')"
    >
      <XCircleIcon />
    </button>
    <div
      class="text-xs text-gray-500 text-ellipsis overflow-hidden relative"
      :class="{
        '-bottom-8': this.clip.type !== 'html',
        '-bottom-2': this.clip.type === 'html',
      }"
    >
      {{ this.clip.startDisplay }}
    </div>
  </div>

</template>

<script>
  import { XCircleIcon } from '@heroicons/vue/24/solid'
  import TitleSlide from './TitleSlide.vue'

  export default {
    components: {
      XCircleIcon,
      TitleSlide,
    },
    computed: {
      style() {
        let percentOfTimeline = this.clip.length / this.timeline.duration;
        let width = `calc(${percentOfTimeline * 100}% - 10px)`;
        return {
          width,
          minWidth: '50px',
          maxWidth: '50%',
          height: '25px',
          backgroundImage: `url(${this.clip.horizontalSprites})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      }
    },
    props: {
      clip: {
        type: Object,
        required: true
      },
      timeline: {
        type: Object,
        required: true
      },
    }
  }

</script>

