<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 78.83 78.84"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g>
          <path
            d="M66.36 39.42a26.94 26.94 0 1 1-26.94-26.94 26.94 26.94 0 0 1 26.94 26.94Z"
            className="cls-1"
            style="animation: fade 2s infinite;"

          />
          <path
            d="M76.29 39.42h-2.55a34.33 34.33 0 1 1-10-24.27 34.21 34.21 0 0 1 10 24.27h5.09a39.42 39.42 0 1 0-39.41 39.42 39.41 39.41 0 0 0 39.41-39.42Z"
            className="cls-1"
          />
        </g>
      </g>
    </svg>
</template>

<script setup></script>
