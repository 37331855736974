<template>
  <span class="inline-flex rounded-md shadow-sm">
  <a
    :href="href"
    :dusk="dusk"
    type="button"
    class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
  >
    <slot></slot>
  </a>
</span>
</template>

<script>
  export default {
    props:{
      dusk:{
        type:String,
        default:''
      },
      href:{
        type:String,
        default:''
      }
    }
  }
</script>
