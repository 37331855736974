<template>
<div class="my-4">
    <div class="flex justify-left items-center">
      <h2 class="text-xl font-bold">Candidate Stats</h2>
      <MinimalButton
        class="ml-2"
        @click="showStats = !showStats"
      >
        <FontAwesomeIcon
          :icon="faAngleDown"
          :rotation="showStats ? 180 : 0"
          class="h-4 w-4 text-blue-500"
        />
      </MinimalButton>
    </div>

    <div
      v-if="showStats"
      class="flex justify-evenly mt-2"
    >
      <MinimalButton
        @click="statRange = 7"
        class="mx-2"
        :class="statRange === 7 ? 'bg-blue-200' : ''"
      >
        7 Days
      </MinimalButton>

      <MinimalButton
        @click="statRange = 30"
        class="mx-2"
        :class="statRange === 30 ? 'bg-blue-200' : ''"
      >
        30 Days
      </MinimalButton>

      <MinimalButton
        @click="statRange = 180"
        class="mx-2"
        :class="statRange === 180 ? 'bg-blue-200' : ''"
      >
        6 Months
      </MinimalButton>
    </div>

    <div
      v-if="showStats"
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2"
    >
      <ViewsLineChart
        :chartData="searchViewsData"
      />

      <ViewsLineChart
        :chartData="clientViewsData"
      />

      <ViewsLineChart
        :chartData="viewsByManagerData"
      />

      <ViewsLineChart
        :chartData="followupsRequestedData"
      />

      <ViewsLineChart
        :chartData="followupsData"
      />
    </div>
</div>
</template>

<script setup>
import ViewsLineChart from './ViewsLineChart.vue';
import MinimalButton from '../MinimalButton.vue';
import { defineProps, computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

const showStats = ref(false);
const statRange = ref(30);

const props = defineProps({
    candidate: {
        type: Object,
        required: true,
    },
});

const viewsByManagerData = computed(() => {
  const rawViews = props.candidate
    .views_by_manager
    .map(manager => {
      return {
        date: DateTime.fromISO(manager.pivot.created_at),
        label: `${manager.client.name}: ${manager.name}`,
      };
    })
    .sort((a, b) => a.date.toMillis() - b.date.toMillis())

  const {labels, data, dataLabels} = formatData(rawViews);

  return {
    labels,
    datasets: [
      {
        label: `Views of ${props.candidate.name}`,
        data,
        dataLabels,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
});

const followupsData = computed(() => {
  const rawViews = props.candidate
    .followups
    .map(followup => {
      return {
        date: DateTime.fromISO(followup.created_at),
        label: `${followup.search.client.name} ${followup.search.name} by  ${followup.requester.name}`,
      };
    })
    .sort((a, b) => a.date.toMillis() - b.date.toMillis())

  const {labels, data, dataLabels} = formatData(rawViews);

  return {
    labels,
    datasets: [
      {
        label: `Followups for ${props.candidate.name}`,
        data,
        dataLabels,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
});

const followupsRequestedData = computed(() => {
  const rawViews = props.candidate
    .requested_followups
    .map(followup => {
      return {
        date: DateTime.fromISO(followup.created_at),
        label: `${followup.search.client.name}: ${followup.search.name}`,
      };
    })
    .sort((a, b) => a.date.toMillis() - b.date.toMillis())

  const {labels, data, dataLabels} = formatData(rawViews);

  return {
    labels,
    datasets: [
      {
        label: `Searches ${props.candidate.name} Likes`,
        data,
        dataLabels,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
});

const clientViewsData = computed(() => {
  const rawViews = props.candidate
    .client_views
    .map(client => {
      return {
        date: DateTime.fromISO(client.pivot.created_at),
        label: `${client.name}`,
      };
    })
    .sort((a, b) => a.date.toMillis() - b.date.toMillis())

  const {labels, data, dataLabels} = formatData(rawViews);

  return {
    labels,
    datasets: [
      {
        label: `Clients Viewed by ${props.candidate.name}`,
        data,
        dataLabels,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
});

const searchViewsData = computed(() => {
  const rawViews = props.candidate
    .search_views
    .map(search => {
      return {
        date: DateTime.fromISO(search.pivot.created_at),
        label: `${search.client.name}: ${search.name}`,
      };
    })
    .sort((a, b) => a.date.toMillis() - b.date.toMillis())

  const {labels, data, dataLabels} = formatData(rawViews);

  return {
    labels,
    datasets: [
      {
        label: `Searches Viewed by ${props.candidate.name}`,
        data,
        dataLabels,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };
});

function formatData(rawViews){
  const viewsByDate = groupViewsByDate(rawViews);

  return {
    labels: Object.keys(viewsByDate)
                  .map(date => date),
    data: Object.keys(viewsByDate)
                  .map(date => viewsByDate[date].count),
    dataLabels: Object.keys(viewsByDate)
                  .map(date => viewsByDate[date].labels),
  }
}

function groupViewsByDate(rawViews){
  const now = DateTime.now();
  const start = now.minus({days: statRange.value});
  const groupedViews = {};

  for(let i = 0; i <= statRange.value; i++){
    const date = start.plus({days: i})
      .toLocaleString(DateTime.DATE_SHORT);

    groupedViews[date] = {
      count: 0,
      labels: [],
    };
  }

  rawViews
    .filter( ({date}) => date > now.minus({days: statRange.value}))
    .forEach( ({date, label}) => {
      const key = date.toLocaleString(DateTime.DATE_SHORT);
      groupedViews[key]['count']++;
      groupedViews[key]['labels'].push(label);
      groupedViews[key]['labels'] = [...new Set(groupedViews[key]['labels'])]
    });

  return groupedViews;
}

</script>
