<template>
  <div class="xl:px-56">
    <p class="my-2 text-gray-600 text-xs">
      Here you can create a new Source File by selecting a region of
      this existing Source File.
      Once you have selected the in and out points, click Render to create the new Source File.
      Rendering can take some time, so please be patient.
    </p>
    <NormalInput
      :value="this.name"
      @input="this.name = event.target.value"
      name="name"
      label="New Source File Name"
    />
    <div class='mt-2'>
      <video ref="videoPlayer" class="video-js vjs-theme-sea"></video>
    </div>
    <div class="text-xs flex justify-between my-2 text-gray-600">
      <div
        class="inline-flex rounded-md shadow-sm"
      >
        <button
          @click="this.markIn"
          title="press i to mark the in point"
          class="relative -ml-px inline-flex items-center rounded-l-md bg-white px-3 py-2 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <ScissorsIcon class="inline-block mr-1 h-4 w-4" aria-hidden="true"/>
          In
        </button>

        <button
          @click="this.markOut"
          title="press o to mark the out point"
          class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          Out
          <ScissorsIcon class="inline-block ml-1 h-4 w-4" aria-hidden="true"/>
        </button>
      </div>

      <div
        class="inline-flex rounded-md shadow-sm"
      >

        <button
          @click="this.backOneSecond"
          class="relative -ml-px inline-flex items-center rounded-l-md bg-white px-2 py-2 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <MinusSmallIcon class="inline-block h-4 w-4" aria-hidden="true"/>
          1
        </button>


        <button
          @click="this.seekToIn"
          class="relative -ml-px inline-flex items-center bg-white px-3 py-2 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <EyeIcon class="inline-block mr-1 h-4 w-4" aria-hidden="true"/>
          In
        </button>

        <button
          @click="this.seekToOut"
          class="relative -ml-px inline-flex items-center bg-white px-3 py-2 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <EyeIcon class="inline-block mr-1 h-4 w-4" aria-hidden="true"/>
          Out
        </button>

        <button
          @click="this.forwardOneSecond"
          class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        >
          <PlusIcon class="inline-block h-4 w-4" aria-hidden="true"/>
          1
        </button>
      </div>


    </div>

    <div class="text-center mt-4">
      <ButtonPrimary
        @click="this.render"
        :disabled="this.renderDisabled"
      >
        <span
          v-if="this.submitting"
          class="inline-flex items-center"
        >
          Rendering
          <ClipLoader
            class="inline-block mx-1 mt-1"
            color="#FFF"
            size="1.5rem"
          />
        </span>

        <span v-else>
          <VideoCameraIcon class="inline-block ml-1 h-4 w-4" aria-hidden="true"/>
          Render
        </span>
      </ButtonPrimary>
    </div>

  </div>
</template>

<script>
import videojs from 'video.js';
import 'videojs-markers-plugin';
import axios from 'axios';
import NormalInput from '../NormalInput.vue';
import ButtonPrimary from '../ButtonPrimary.vue';
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { ScissorsIcon, PlusIcon, MinusSmallIcon, VideoCameraIcon, EyeIcon } from '@heroicons/vue/24/solid';
import Clip from './Clip';

export default {
  components:{
    ScissorsIcon,
    PlusIcon,
    MinusSmallIcon,
    VideoCameraIcon,
    EyeIcon,
    ClipLoader,
    NormalInput,
    ButtonPrimary,
  },
  props: {
    sourceFile: {
      type: Object,
      required: true,
    },
    trimSourceFileUrl: {
      type: String,
      required: true
    },
  },
  data(){
    return {
      player: null,
      markers: {
        in:null,
        out:null
      },
      name: this.sourceFile.name,
      submitting: false,
    }
  },
  computed:{
    renderDisabled(){
      return !this.markers.in || !this.markers.out || this.submitting;
    }
  },

  methods:{
    markIn(){
      this.markers.in = {
        time: this.player.currentTime(),
        text: 'In',
        class: 'bg-green-400'
      };
      this.setMarkers();
    },
    markOut(){
      this.markers.out = {
        time: this.player.currentTime(),
        text: 'Out',
        class: 'bg-red-400'
      };
      this.setMarkers();
    },
    setMarkers(){
      let markers = [this.markers.in, this.markers.out]
        .filter( m => m )
      this.player.markers.reset(markers)
    },
    seekToIn(){
      this.player.pause();
      this.player.currentTime(this.markers.in.time);
    },
    seekToOut(){
      this.player.pause();
      this.player.currentTime(this.markers.out.time);
    },

    forwardOneSecond(){
      this.player.pause();
      this.player.currentTime(this.player.currentTime() + 1);
    },

    backOneSecond(){
      this.player.pause();
      this.player.currentTime(this.player.currentTime() - 1);
    },
    async render(){
      this.submitting = true;

      this.player.pause();

      const clip = new Clip({
        name: this.name,
        start: 0,
        length: this.markers.out.time - this.markers.in.time,
        type: 'video',
        src: this.sourceFile.original_url,
        trim: this.markers.in.time,
        transitionIn: "none",
        transitionOut: "none",
      });

      const trimUrl = this.trimSourceFileUrl.replace('000', this.sourceFile.id);

      const response = await axios.post(trimUrl, clip);

      this.$emit('done', response.data);
    },
  },

  mounted() {
    this.player = videojs(
      this.$refs.videoPlayer,
      {
        controls:true,
        sources: [
          {
            src: this.sourceFile.original_url,
          }
        ],
        poster: this.sourceFile.preview_url,
        preload:'auto',
        experimentalSvgIcons:true,
        fluid:true,
        inactivityTimeout: 0,
        skipButtons: {
          forward: 1,
          back: 1,
        },
        userActions: {
          hotkeys: (event) => {
            if(event.key === 'i'){
              this.markIn();
            }
            if(event.key === 'o'){
              this.markOut();
            }
            if(event.key === ' '){
              if(this.player.paused()){
                this.player.play();
              } else {
                this.player.pause()
              }
            }
          }
        },
      },
      () => {
        this.player.markers({
          markerStyle: {
            'width':'4px',
          },
          onMarkerClick: (marker)=>{
            this.player.seek(marker.time);
          },
          onMarkerReached: (marker)=>{

          },
          markers: []
        });
      }
    );
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>
