<template>

  <NotificationsDropdown
    :notifications="notifications"
    :user="props.user"
  />

</template>

<script setup>
import NotificationsDropdown from './NotificationsDropdown.vue';
import { defineProps, ref, computed, onMounted } from 'vue';

const notifications = ref([]);

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});

onMounted(()=>{
  Echo.private(`App.User.${props.user.uuid}`)
    .listen('MissedMessagesEvent', data => {
      notifications.value = [
        ...notifications.value,
        {
          type: 'missed-messages',
          data
        }
      ];
    });
})

</script>
