<template>
    <div class="my-4">
        <h3
          v-if="title"
          class="text-lg font-bold"
        >
          {{ title }}
        </h3>
        <p
          v-if="subTitle"
          class="text-gray-500"
        >
          {{ subTitle }}
        </p>
        <div class="relative h-48">
          <Line
            :data="chartData"
            :options="chartOptions"
            :style="chartStyle"
          />
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement)

const props = defineProps({
  chartData: {
      type: Array,
      required: true,
  },
  chartOptions: {
      type: Object,
      default: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            min: 0,
            ticks: {
              stepSize: 1
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                if(context.dataset.dataLabels) {
                  return context.dataset.dataLabels[context.dataIndex];
                }
                return `${context.label}: ${context.formattedValue}`;
              }
            }
          }
        }
      }
  },
  title: {
      type: String,
  },
  subTitle: {
      type: String,
  },
});

const chartStyle = computed(() => {
    return {
        // height: '200px',
    };
});

</script>
