<template>
  <span ref="element" class="ml-3 inline-flex rounded-md shadow-sm">
    <button
        @click="clicked"
        :dusk="dusk"
        :type="type"
        :disabled="disabled"
        class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out disabled:bg-indigo-300 disabled:cursor-not-allowed disabled:hover:bg-indigo-300"
    >
        <slot></slot>
    </button>
  </span>
</template>

<script setup>
import { ref, defineProps,  } from 'vue'

const props = defineProps({
  dusk:{
    type:String,
    default:''
  },
  type:{
    type:String,
    default:'submit'
  },
  disabled:{
    type:Boolean,
    default:false
  }
});

const emit = defineEmits(['clicked']);

const element = ref(null);

function clicked(e){
  let button = e.target;
  let form = element.value.closest('form');
  if(form){
    form.addEventListener('submit', function(e){
      button.disabled = true;
    });
  }
  emit('click', e)
}

</script>
