import Clip from './Clip';

export default class Timeline {

  constructor(timeline){
    Object.assign(this, timeline);

    if(timeline){
      this.clips = timeline.clips
        .map( props => new Clip(props) );
    }
  }

  background = '';
  clips = [];

  get duration(){
    return this.clips.reduce( (total, clip) => total + clip.length, 0);
  }

  get durationDisplay(){
    let minutes = Math.floor(this.duration / 60);
    let seconds = Math.floor(this.duration % 60);
    if(seconds < 10) seconds = `0${seconds}`;
    return `${minutes}:${seconds}`;
  }

  addClip(clip, index){
    const previousClip = this.clips[index - 1];

    if(previousClip){
      clip.start = previousClip.start + previousClip.length;
    }

    // add the clip at the index
    this.clips.splice(index, 0, clip);
    // adjust future clips start time
    this.clips.slice(index + 1).forEach( nextClip => nextClip.start += clip.length);
  }

  moveClip(newIndex, oldIndex){
    const clip = this.clips.splice(oldIndex, 1)[0];
    // order of operations is important here
    //move the clip into place
    this.clips.splice(newIndex, 0, clip);

    // adjust all the previous clips start time
    this.clips.slice(0, newIndex)
      .forEach( (previous, index) => {
        if(index === 0) previous.start = 0;
        else previous.start = this.clips[index - 1].start + this.clips[index - 1].length;
      });

    // adjust this clips start time if its not the first clip
    const previousClip = this.clips[newIndex - 1];
    clip.start = previousClip
      ? previousClip.start + previousClip.length
      : 0;

    // adjust the future clips start time
    this.clips.slice(newIndex + 1)
      .forEach( (next, subIndex) => {
        let previous = this.clips[newIndex + subIndex];
        next.start = previous.start + previous.length;
      });
  }

  removeClip(index){
    const clip = this.clips.splice(index, 1)[0];
    // adjust the future clips start time
    this.clips.slice(index)
        .forEach( c => c.start -= clip.length);
    return clip;
  }
}
