<template>
  <div class="space-y-1">
    <div class="relative">
      <span class="inline-block w-full rounded-md shadow-sm">
        <button
          @click="toggleSelect"
          type="button"
          dusk="portals"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
        >
          <span class="w-full inline-flex space-x-2 truncate">
            <span class="text-gray-500">
              Change Portal
            </span>
          </span>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
              <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </span>
        </button>
      </span>

      <div
        v-show="selectOpen"
        class="absolute mt-1 w-full rounded-md bg-white shadow-lg"
      >
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          class="max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black/5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
        >
          <!--
            Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

            Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
          -->
          <li
            @click="selectOption()"
            v-bind:class="highlight === 'admin' ? 'text-white bg-indigo-600' : 'text-gray-900'"
            @mouseover="highlight = 'admin'"
            @mouseout="highlight = null"
            :id="`portal-admin`"
            :dusk="`portal-admin`"
            role="option"
            class="cursor-pointer select-none relative py-2 pl-3 pr-9"
          >
            <div class="flex space-x-2">
              <span class="font-normal">
                Admin Section
              </span>
            </div>
          </li>


          <li
            v-for="tenant in tenants" :key="tenant.id"
            @click="selectOption(tenant)"
            v-bind:class="highlight === tenant ? 'text-white bg-indigo-600' : 'text-gray-900'"
            @mouseover="highlight = tenant"
            @mouseout="highlight = null"
            :id="`portal-${tenant.id}`"
            :dusk="`portal-${tenant.id}`"
            role="option"
            class="cursor-pointer select-none relative py-2 pl-3 pr-9"
          >
            <div class="space-x-2">
              <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
              <div class="font-normal">
                {{tenant.name}}
              </div>
              <!-- Highlighted: "text-indigo-200", Not Highlighted: "text-gray-500" -->
              <div
                v-bind:class="highlight === tenant ? 'text-white' : 'text-gray-500'"
                class="text-sm"
              >
                {{tenant.portal}}
              </div>
            </div>

            <!--
              Checkmark, only display for selected option.

              Highlighted: "text-white", Not Highlighted: "text-indigo-600"
            -->
            <!-- <span class="absolute inset-y-0 right-0 flex items-center pr-4">
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </span> -->
          </li>

          <!-- More options... -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    tenants:{
      type:[Array,Object],
      default:[],
    },
    inverse:{
      type:Boolean,
      default:false
    }
  },

  data(){
    return {
      selectOpen:false,
      highlight:null,
    }
  },

  methods:{
    toggleSelect(){
      this.selectOpen = !this.selectOpen;
    },
    selectOption(tenant){
      if(tenant){
        window.location = `/admin/changePortal/${tenant.id}`
      } else {
        window.location = `/admin/resetPortal`
      }
    },
  }

}
</script>
