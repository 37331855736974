<template>
  <div :class="containerClass">
    <button
      :class="classNames
        ? classNames
        : 'text-red-600 hover:text-red-900'"
      :dusk="dusk"
      :disabled="disabled"
      :title="hoverTitle"
      v-on:click="showModal"
    >
      <slot>
        {{buttonText}}
      </slot>
    </button>
    <form
      v-unless="emits"
      ref="deleteForm"
      style="display: none;"
      :action="route"
      method="POST"
    >
      <input type="hidden" name="_method" value="DELETE">
      <input type="hidden" name="_token" :value="csrf">
    </form>

    <SimpleModal
      @confirm="handleModalConfirm"
      @cancel="handleModalCancel"
      :title="title"
      :open="modalOpen"
    >
      {{warning}}
    </SimpleModal>
  </div>
</template>

<script setup>
  import { ref, defineProps, defineEmits } from 'vue';
  import SimpleModal from './SimpleModal.vue';

  const props = defineProps({
    route: {
      type: String,
      default: ''
    },
    emits: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Are you sure?'
    },
    hoverTitle: {
      type: String,
      default: 'Delete'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    warning: {
      type: String,
      default: 'This action cannot be undone.'
    },
    dusk: {
      type: String,
    },
    classNames: {
      type: String,
      default: ''
    },
    containerClass: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Delete'
    }
  });

  const emit = defineEmits(['confirm']);

  const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const modalOpen = ref(false);
  const deleteForm = ref(null);

  function showModal(){
    modalOpen.value = true;
  }

  function handleModalConfirm(){
    if(props.emits){
      emit('confirm');
      handleModalCancel();
    } else {
      deleteForm.value.submit();
    }
  }

  function handleModalCancel(){
    modalOpen.value = false;
  }

</script>
