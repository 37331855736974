<template>
  <div ref="element" class="mt-6 sm:mt-5">
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">

      <label
        v-if="label"
        :for="name"
        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
      >
        {{label}}
      </label>


      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="relative rounded-md shadow-sm" data-select>
          <span class="inline-block w-full rounded-md shadow-sm">
            <button
              @click="toggleSelect"
              type="button"
              :dusk="dusk"
              aria-haspopup="listbox"
              aria-expanded="true"
              aria-labelledby="listbox-label"
              class="cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pl-3 py-2 text-left text-gray-900 focus:outline-none focus:ring-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-gray-400"
              :disabled="disabled"
            >
              <span class="w-full inline-flex space-x-2 truncate">
                <span
                  v-if="currentSelection"
                  v-html="currentSelection[optionsLabel]"
                ></span>

                <span
                  v-else
                  class="text-gray-500"
                >
                  {{placeholder}}
                </span>
              </span>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                  <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </button>

            <div
              v-if="error"
              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
          </span>

          <div
            v-if="selectOpen"
            class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10"
          >
            <div
              class="p-2"
              v-if="searchKey"
            >
              <input
                v-model="search"
                type="text"
                class="w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue-300 sm:text-sm"
                placeholder="Search..."
              >
            </div>
            <ul
              tabindex="-1"
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-item-3"
              class="max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black/5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
            >

              <li
                v-for="option in filteredOptions" :key="option[optionsKey]"
                @click="selectOption(option)"
                :class="highlight === option[optionsKey] ? 'text-white bg-indigo-600' : 'text-gray-900 bg-white'"
                @mouseover="highlight = option[optionsKey]"
                @mouseout="highlight = null"
                :id="`option-${option[optionsKey]}`"
                :dusk="`option-${option[optionsKey]}`"
                role="option"
                class="cursor-pointer select-none relative py-2 pl-3 pr-9"
              >
                <div
                  class="space-x-2"
                  :class="currentSelection && (currentSelection[optionsKey] === option[optionsKey]) ? 'font-semibold' : 'font-normal'"
                  v-html="option[optionsLabel]"
                ></div>

                <span
                  v-if="currentSelection && (currentSelection[optionsKey] === option[optionsKey])"
                  class="absolute inset-y-0 right-0 flex items-center pr-4">
                  <FontAwesomeIcon
                    v-if="highlight === option[optionsKey]"
                    :icon="faBan"
                    class="h-5 w-5 text-red-600"
                  />
                  <FontAwesomeIcon
                    v-else
                    :icon="faCheck"
                    class="h-5 w-5"
                  />
                </span>
              </li>

              <li
                v-if="options.length === 0"
                class="cursor-not-allowed select-none relative py-2 pl-3 pr-9 text-gray-500 text-light italic"
              >
                No options available
              </li>

            </ul>
          </div>
        </div>
        <p v-if="error" class="mt-2 text-sm text-red-600">
          {{error}}
        </p>
      </div>
    </div>
    <input
      v-if="currentSelection"
      type="hidden"
      :name="name"
      :value="currentSelection[optionsKey]"
    >
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  onBeforeUnmount
} from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck, faBan } from '@fortawesome/pro-regular-svg-icons';

const props = defineProps({
  options:{
    type:[Array,Object],
    default:[],
  },
  selectedOption:{
    type:Object,
    default:null
  },
  emitOnly:{
    type:Boolean,
    default:false
  },
  optionsKey:{
    type:String,
    default:'id'
  },
  optionsLabel:{
    type:String,
    default:'name'
  },
  searchKey:{
    type:String,
    default:''
  },
  dusk:{
    type:String,
    default:''
  },
  name:{
    type:String,
    default:''
  },
  label:{
    type:String,
    default:''
  },
  placeholder:{
    type:String,
    default:''
  },
  error:{
    type:String,
    default:''
  },
  disabled:{
    type:Boolean,
    default:false
  }
});

const emit = defineEmits(['single-select']);

const selectOpen = ref(false);
const highlight = ref(null);
const selected = ref();
const element = ref(null);
const search = ref(null);

onMounted( () => {
  document.addEventListener('click',closeSelect);
});
onBeforeUnmount( () => {
  document.removeEventListener('click',closeSelect);
});

const filteredOptions = computed( () => {
  if(!search.value){
    return props.options;
  }
  return props.options.filter( option => {
    return option[props.optionsLabel].toLowerCase().includes(search.value.toLowerCase());
  });
});

const currentSelection = computed( () => {
  if(selected.value === null){
    return undefined;
  }
  return selected.value || props.selectedOption;
});

    function toggleSelect(){
      search.value = '';
      selectOpen.value = !selectOpen.value;
    }

    function closeSelect(event){
      let thisWasClicked = element.value
        .querySelector('[data-select]')
        .contains(event.target);

      if(thisWasClicked === false){
        selectOpen.value = false;
      }
    }

    function selectOption(option){
      const currentSelection = selected.value ?? props.selectedOption;
      if(currentSelection && currentSelection[props.optionsKey] === option[props.optionsKey]){
        option = null;
      }
      if(!props.emitOnly){
        selected.value = option;
      }
      toggleSelect();
      emit('single-select', option);
    }
</script>
