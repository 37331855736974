<template>
    <div id="notifications-menu" class="ml-3 relative">
      <div>
        <button
          @click.stop="toggleOpen"
          class="relative max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring"
          id="notifications-menu-button"
          aria-label="Notifications Menu"
          aria-haspopup="true"
        >
          <div
            v-if="showBadge"
            class="absolute animate-ping top-0 right-0 h-2 w-2 rounded-full bg-red-500"
            title="Unread notifications"
          ></div>
          <FontAwesomeIcon
            :icon="faBell"
            class="h-6 text-gray-700 hover:text-gray-500"
          />
        </button>
      </div>

      <transition
        enter-active-class= "transition ease-out duration-100"
        active-class= "transform opacity-0 scale-95"
        active-to-class= "transform opacity-100 scale-100"
        leave-active-class= "transition ease-in duration-75"
        leave-class= "transform opacity-100 scale-100"
        leave-to-class= "transform opacity-0 scale-95"
      >
        <div
          id="user-menu-dropdown"
          v-show="isOpen"
          class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
          <div class="py-1 rounded-md bg-white ring-1 ring-black/5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">

            <NotificationItem
              v-for="notification in props.notifications"
              :notification="notification"
              :user="user"
            />

          </div>
        </div>
      </transition>
    </div>
</template>

<script setup>
import {faBell} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import NotificationItem from './NotificationItem.vue';
import {ref, defineProps, computed} from 'vue';

const props = defineProps({
  user: {
    type: Object,
    required: true
  },
  notifications: {
    type: Array,
    default: []
  }
});

const isOpen = ref(false);
const seenNotifications = ref(0);

const showBadge = computed(() => {
  return seenNotifications.value < props.notifications.length;
});

function toggleOpen(){
  if(isOpen.value === false){
    window.addEventListener('click', close);
    isOpen.value = true;
  } else {
    close();
  }
}

function close(){
  window.removeEventListener('click', close);
  isOpen.value = false;
}

</script>
