<script setup>
import { useStore } from 'vuex'
import { onMounted, defineProps, computed } from 'vue';

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});
const store = useStore();

const canSeeOnlineUsers = computed(() => {
  return true;
  return props.user.type === 'admin' ||
         props.user.type === 'recruiter';
});

onMounted(() => {
  Echo.join(`App.Presence`)
    .here(users => {
      if(!canSeeOnlineUsers.value){
        return;
      }
      store.commit('setOnlineUsers', users);
    })
    .joining(user => {
      if(!canSeeOnlineUsers.value){
        return;
      }
      const users = store.state.onlineUsers;
      const onlineUsers = [...users, user];
      store.commit('setOnlineUsers', onlineUsers);
    })
    .leaving(user => {
      if(!canSeeOnlineUsers.value){
        return;
      }
      const users = store.state.onlineUsers;
      const onlineUsers = users.filter(u => u.uuid !== user.uuid);
      store.commit('setOnlineUsers', onlineUsers);
    })
    .error(error => {
      console.error(error);
    });
});

</script>



<template></template>
