export default {
	state: {
    navOpen: false,
    profileOpen: false,
    onlineUsers: [],
	},

  mutations: {
    toggleNav(state){
      state.navOpen = !state.navOpen;
    },
    toggleProfile(state){
      state.profileOpen = !state.profileOpen;
    },
    setOnlineUsers(state, users){
      state.onlineUsers = users;
    },
  }
}
