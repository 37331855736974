<template>
  <div class="mt-6 sm:mt-5">
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">

      <label
        for="{{this.name}}"
        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
      >
        <slot name="label"></slot>
      </label>

      <div class="mt-1 sm:mt-0 sm:col-span-2">
        <div class="relative  rounded-md shadow-sm">
          <div
            class="rich-text-editor form-input block w-full transition duration-150 ease-in-out border-gray-300 rounded-md"
            ref="editorCanvas"
            :dusk="this.dusk"
          ></div>

          <input
            :id="this.name"
            type="hidden"
            :name="this.name"
            :value="this.content"
            class="form-input block w-full transition duration-150 ease-in-out border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5"
          >

          <div
            v-if="this.error"
            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
          >
            <svg class="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>

        </div>
        <p
          v-if="this.error"
          class="mt-2 text-sm text-red-600"
        >
          {{this.error}}
        </p>
      </div>

    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import EditorJS from '@editorjs/editorjs';
import Header from 'editorjs-header-with-alignment';
import Paragraph from 'editorjs-paragraph-with-alignment'
import List from "@editorjs/list";

export default {
  setup() {
    const editorCanvas = ref(null)
    return {
      editorCanvas,
    }
  },
  props:{
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: {},
    },
    dusk: {
      type: String,
      required: false,
    },
    error: {
      type: String,
      required: false,
    },
  },
  data(){
    return {
      editor: null,
      content: JSON.stringify(this.value)
    }
  },
  methods:{
    async handleEditorChange(event, customEvent){
      let content = await this.editor.save();
      this.content = JSON.stringify(content);
      this.$emit('input', content);
    },
  },
  mounted(){
    this.editor = new EditorJS({
      holder: this.editorCanvas,
      onChange: this.handleEditorChange,
      data: this.value,
      logLevel: 'ERROR',
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: 'Enter a header',
            levels: [2, 3, 4],
            defaultLevel: 3,
            defaultAlignment: 'left'
          }
        },
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered'
          }
        },
      },
    });
  },
  unmounted() {
    this.editor.destroy();
  },
}
</script>




<!--
{
  "type" : "list",
  "data" : {
      "style" : "unordered",
      "items" : [
          "This is a block-styled editor",
          "Clean output data",
          "Simple and powerful API"
      ]
  }
}

{
  "type" : "paragraph",
  "data" : {
      "text" : "Check out our projects on a <a href=\"https://github.com/codex-team\">GitHub page</a>.",
      "alignment": "left"
  }
}

{
  "type": "header",
  "data": {
    "text": "Why Telegram is the best messenger",
    "level": 2,
    "align": "left"
  }
}




}, -->
