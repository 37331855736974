<template>
  <div>
    <p class="my-2 text-xs text-gray-600">
      This title slide will default to 5 seconds long when added to the timeline.
      Choose the font options and text that you would like to use.
    </p>
    <NormalInput
      :value="this.tempName"
      @input="this.updateName"
      name="name"
      label="Title Slide Name"
    />
    <div
      class="grid-cols-6 gap-4 grid"
    >
      <SelectSingle
        class="col-span-3"
        :options="this.fontFamilies"
        :selectedOption="this.fontFamily"
        label="Font"
        name="font"
        @single-select="this.updateFontFamily"
      />
      <NormalInput
        class="col-span-2"
        :value="this.fontSize"
        @input="this.updateFontSize"
        type="number"
        label="Font Size"
      />
      <SelectSingle
        class="col-span-1"
        :options="this.fontAlignments"
        :selectedOption="this.fontAlignment"
        label=" "
        name="alignment"
        @single-select="this.updateFontAlignment"
      />
    </div>
    <div
      class="relative overflow-hidden mt-4 border-t"
      ref="container"
    >
      <table
        class="absolute"
        ref="editorTable"
      >
        <tr>
          <td>
          <div id="editor" class="title-slide-editor">
          </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="flex justify-end mt-4">
      <ButtonSecondary
        @click="this.$emit('cancel')"
      >
        Cancel
      </ButtonSecondary>

      <ButtonPrimary
        @click="this.save"
        :disabled="this.submitting"
      >
        Save
      </ButtonPrimary>
    </div>
  </div>
</template>


<script>
  import EditorJS from '@editorjs/editorjs';
  import ButtonPrimary from '../ButtonPrimary.vue';
  import ButtonSecondary from '../ButtonSecondary.vue';
  import NormalInput from '../NormalInput.vue';
  import SelectSingle from '../SelectSingle.vue';
  import FontFamilies from './FontFamilies';
  import FontAlignments from './FontAlignments';

  export default {
    components: {
      ButtonPrimary,
      ButtonSecondary,
      NormalInput,
      SelectSingle,
    },
    props: {
      sourceFile: {
        type: Object,
        required: false,
        default: () => {
          return {
            type: 'html',
            html: {
              html: '<table><tr><td><p class="editor-block">Title Slide</p></td></tr></table>',
              css: `
                .editor-block {margin: auto; text-align: center; color:white; font-family: 'Montserrat'; font-size: 150px;}
                table {width: 1920px; height: 1080px; text-align: center;}
                td {background-color: black; vertical-align: middle; text-align: center; color:white; font-family: 'Montserrat'; font-size: 150px;}
              `,
              font:
                {
                  id: 'Montserrat',
                  name: '<span style="font-family:Montserrat;">Montserrat</span>',
                  value: 'Montserrat',
                },
            },
          }
        },
      },
    },
    data() {
      return {
        editor: null,
        submitting: false,
        tempName: this.sourceFile.name,
        tempCss: this.sourceFile.html.css,
        fontFamilies: FontFamilies,
        fontAlignments:FontAlignments,
      }
    },
    computed: {
      editorData(){
        let template = document.createElement('template');
        template.innerHTML = this.sourceFile.html.html;
        let nodeList = template.content.querySelectorAll('p');
        let blocks = [];
        nodeList.forEach( node => {
          let block = {
            type: 'paragraph',
            data: {
              text: node.innerHTML
            }
          }
          blocks.push(block);
        });
        return {blocks};
      },
      fontSize(){
        let fontSize = this.tempCss.match(/font-size: (\d+)px/);
        if(fontSize){
          return fontSize[1];
        }
        return 100;
      },
      fontFamily(){
        let values = this.tempCss.match(/font-family: '([^;']+)';/);
        if(values){
          return this.fontFamilies.find(font => font.value === values[1])
        }
        return this.fontFamilies[0];
      },
      fontAlignment(){
        let values = this.tempCss.match(/text-align: ([^;]+);/);
        if(values){
          return this.fontAlignments.find(font => font.value === values[1])
        }
        return this.fontAlignments[0];
      },
    },
    methods: {
      async save(){
        this.submitting = true;

        let {blocks} = await this.editor.save()
        let html = blocks.map( block => {
          if(block.type === 'paragraph'){
            return `<p class="editor-block">${block.data.text}</p>`
          }
        }).join('');

        this.sourceFile.html.html = `${html}`;
        this.sourceFile.html.css = this.tempCss;
        this.sourceFile.html.font = this.fontFamily;
        this.sourceFile.name = this.tempName;

        this.$emit('save', this.sourceFile);
      },

      updateFontSize(event){
        let fontSize = event.target.value;
        if(!fontSize) return;
        this.tempCss = this.tempCss.replace(/font-size: \d+px/g, `font-size: ${fontSize}px`);
        this.restyleEditor();
      },

      updateFontAlignment(fontAlignment){
        this.tempCss = this.tempCss.replace(/text-align: [^;]+;/g, `text-align: ${fontAlignment.value};`);
        this.restyleEditor();
      },

      updateName(event){
        this.tempName = event.target.value;
      },

      updateFontFamily(fontFamily){
        this.tempCss = this.tempCss.replace(/font-family: [^;]+;/g, `font-family: '${fontFamily.value}';`);
        this.restyleEditor();
      },

      resizeEditor(){
        let containerWidth = this.$refs.container.getBoundingClientRect().width;
        let scale = containerWidth / 1920;
        this.$refs.editorTable.style.transform = `scale(${scale})`;
        this.$refs.editorTable.style.transformOrigin = 'top left';
        this.$refs.container.style.height = `${containerWidth * 0.5625}px`;
      },

      restyleEditor() {
        if(this.styleElement){
          this.styleElement.remove();
        }
        this.styleElement = document.createElement('style');
        this.styleElement.innerText = this.tempCss;
        document.head.append(this.styleElement);
      },

    },
    mounted(){
      this.editor = new EditorJS({
        autofocus: true,
        holder: 'editor',
        tools: {},
        data: this.sourceFile.html.html,
        onChange: this.handleEditorChange,
        onReady: this.onInit,
        data: this.editorData,
      });

      this.restyleEditor();
      this.resizeEditor();
    },
    unmounted() {
        this.editor.destroy();
        this.styleElement.remove();
    },
  }
</script>
