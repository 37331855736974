<template>
  <li class="flex shadow-sm rounded-md mb-2 relative">
    <a
      :href="file.url"
      target="_blank"
      download
    >
      <div class="relative shrink-0 flex items-center justify-center w-16 h-16 bg-indigo-600 text-white text-sm leading-5 font-medium rounded-l-md">
        <svg
          v-if="willBeDeleted"
          class="absolute text-red-600 w-full h-full"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"/>
        </svg>

        <img
          class="h-full w-full rounded-l-md"
          v-if="file.thumbnail || file.preview_url"
          :src="file.thumbnail || file.preview_url"
          alt="thumbnail"
        >
        <div
          v-else
          v-html="icon"
          class="text-indigo-100 w-full"
        ></div>
      </div>
    </a>

    <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
      <div class="flex-1 px-4 py-2 text-sm leading-5 truncate">
        <a
          :href="file.url"
          :title="file.name"
          target="_blank"
          class="text-blue-900 font-medium hover:underline hover:text-blue-600 transition ease-in-out duration-150 "
          download
        >
          {{file.name}}
          <span
            v-if="isNew"
            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-yellow-100 text-yellow-800"
          >
            New
          </span>
          <span
            v-else-if="willBeDeleted"
            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-red-100 text-red-800"
          >
            To Be Deleted
          </span>
          <span
            v-else-if="isSaved"
            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800"
          >
            Saved
          </span>

        </a>
        <p class="text-gray-500">
          {{fileType}}
        </p>
      </div>
      <div class="shrink-0 pr-2">
        <slot name="actions"></slot>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props:['file','isNew','isSaved','willBeDeleted'],
  computed:{
    fileType(){
      let {mime_type=''} = this.file;
      if(mime_type.includes('image')){
        return 'Image';
      } if(mime_type.includes('doc')){
        return 'Word Document';
      } if(mime_type.includes('text')){
        return 'Text File';
      } if(mime_type.includes('video')){
        return 'Video';
      } if(mime_type.includes('pdf')){
        return 'PDF';
      }
    },

    icon(){
      let {mime_type=''} = this.file;
      if(mime_type.includes('image')){
        return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd"/>
        </svg>
        `;
      } if(mime_type.includes('doc')){
        return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd"/>
        </svg>
        `;
      } if(mime_type.includes('text')){
        return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd"/>
        </svg>
        `;
      } if(mime_type.includes('video')){
        return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"/>
        </svg>
        `;
      } if(mime_type.includes('pdf')){
        return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z" clip-rule="evenodd"/>
        </svg>
        `;
      }

      return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clip-rule="evenodd"/>
        </svg>
      `;
    }
  }
}
</script>
