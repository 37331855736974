<template>
<div class="mt-8 border-t-1 border-slate-200">
  <a
    v-if="this.needsAuth"
    @click.prevent="this.launchAuthFlow"
    dusk="authorize-zoom"
    target="popup"
    type="button"
    class="cursor-pointer rounded-md bg-orange-600 py-1.5 px-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
  >
    Authorize Your Zoom Account
  </a>
  <ul
    v-if="this.zoomMeetings && this.zoomMeetings.length > 0"
    role="list"
    class="divide-y divide-gray-300"
  >
    <li v-for="meeting in this.zoomMeetings" :key="meeting.id" class="py-4">
      <div class="flex space-x-3">
        <div class="flex-1 space-y-1">
          <div class="flex items-center justify-between">
            <h3 class="text-sm font-medium text-slate-900">{{ meeting.topic }}</h3>
            <p class="text-sm text-slate-400">{{this.parseDateTime(meeting.start_time)}}</p>
          </div>
          <div
            v-for="recording in meeting.recordings"
            class="text-sm text-slate-500 flex space-x-2 px-4"
          >
            <a :href="recording.play_url" target="_blank">
              <EyeIcon
                class="h-6 w-6 align-middle"
                :class="this.selectedRecordings.includes(recording) ? 'text-blue-600' : 'text-slate-400'"
              ></EyeIcon>
            </a>
            <a :href="recording.download_url" download>
              <CloudArrowDownIcon
                class="h-6 w-6 align-middle"
                :class="this.selectedRecordings.includes(recording) ? 'text-blue-600' : 'text-slate-400'"
              ></CloudArrowDownIcon>
            </a>
            <label>
              <input
                @change="this.toggleRecording(meeting, recording)"
                :checked="this.selectedRecordings.includes(recording)"
                aria-describedby="comments-description"
                type="checkbox"
                class="h-4 w-4 pr-4 align-middle rounded border-gray-300 text-blue-600 focus:ring-blue-600"
              />
              <span :class="this.selectedRecordings.includes(recording) ? 'text-blue-600' : 'text-slate-400'">
                Started at
                {{this.parseTime(recording.recording_start)}}
              </span>
              -
              <span class="text-slate-900">{{recording.duration}}</span>
            </label>
          </div>
        </div>
      </div>
    </li>
  </ul>
  <span
    v-if="this.zoomMeetings && this.zoomMeetings.length === 0"
  >
    No recordings found under your user account.
  </span>

  <div class="mt-4" v-if="this.zoomMeetings" >
    <ButtonPrimary
      @click="this.import"
      dusk="import-zoom"
      :disabled="this.selectedRecordings.length === 0 || this.importing"
    >
      Import Recordings
    </ButtonPrimary>
  </div>

</div>
</template>

<script>
  import axios from 'axios';
  import {DateTime} from 'luxon';
  import ButtonPrimary from './ButtonPrimary.vue';
  import {EyeIcon, CloudArrowDownIcon} from '@heroicons/vue/24/solid'

  export default {
    components: {
      EyeIcon,
      CloudArrowDownIcon,
      ButtonPrimary,
    },
    data() {
      return {
        needsAuth: false,
        zoomMeetings: null,
        selectedRecordings: [],
        importing: false,
      };
    },
    methods:{
      launchAuthFlow(){
        window.open('/api/zoom/authorization', 'popup', 'width=600,height=600');
      },

      authCompleted(event){
        if(event.data?.auth === 'Zoom'){
          this.importRecordings();
        }
      },

      async importRecordings(){
        try{
          let response = await axios.get('/api/zoom/recordings');
          this.needsAuth = false;
          this.zoomMeetings = response.data
        } catch(e){
          this.needsAuth = e.response.status === 401;
        }
      },

      parseDateTime(string){
        return DateTime.fromISO(string).toRelativeCalendar()
      },
      parseTime(string){
        return DateTime.fromISO(string).toFormat('h:mm:ss a')
      },

      toggleRecording(meeting, recording){
        recording.name = meeting.topic + ' - ' + this.parseDateTime(meeting.start_time);
        if(this.selectedRecordings.includes(recording)){
          this.selectedRecordings = this.selectedRecordings.filter(r => r !== recording);
        } else {
          this.selectedRecordings.push(recording);
        }
      },

      import(){
        let sourceFiles = this.selectedRecordings
          .map(recording => {
            return {
              type: 'video',
              url: recording.download_url,
              duration: recording.duration_in_seconds,
              name: recording.name,
            }
        });
        this.importing = true;
        this.$emit('import', sourceFiles);
      }
    },

    created() {
      window.addEventListener('message',this.authCompleted);
    },
    mounted(){
      this.importRecordings();
    },
    destroyed() {
      window.removeEventListener('message', this.authCompleted);
    }
  }
</script>
