<template>
  <span class="ml-3 inline-flex rounded-md shadow-sm">
    <button
        @click="$emit('clicked', $event)"
        :dusk="dusk"
        :type="type"
        :disabled="this.disabled"
        class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo active:bg-indigo-700 transition duration-150 ease-in-out disabled:bg-indigo-300 disabled:cursor-not-allowed disabled:hover:bg-indigo-300"
    >
        <slot></slot>
    </button>
  </span>
</template>

<script>
  export default {
    props:{
      dusk:{
        type:String,
        default:''
      },
      type:{
        type:String,
        default:''
      },
      disabled:{
        type:Boolean,
        default:false
      }
    }
  }
</script>
