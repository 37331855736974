<template>
  <span class="ml-3 inline-flex rounded-md shadow-sm">
    <button
        :dusk="dusk"
        :type="type"
        :disabled="disabled"
        class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 bg-white hover:bg-gray-100 disabled:bg-gray-200 disabled:hover:bg-gray-200 disabled:cursor-not-allowed active:text-gray-800 transition duration-150 ease-in-out"
    >
        <slot></slot>
    </button>
  </span>
</template>

<script>
  export default {
    props:{
      dusk:{
        type:String,
        default:''
      },
      type:{
        type:String,
        default:''
      },
      disabled:{
        type:Boolean,
        default:false
      }
    }
  }
</script>
