export default class Clip {
  start = 0;
  length = 0;
  type = 'video';
  src = '';
  transitionIn = "none";
  transitionOut = "none";

  constructor(props){
    Object.assign(this, props)
  }

  get lengthDisplay(){
    let minutes = Math.floor(this.length / 60);
    let seconds = Math.floor(this.length % 60);
    if(seconds < 10) seconds = `0${seconds}`;

    return `${minutes}:${seconds}`;
  }

  get startDisplay(){
    let minutes = Math.floor(this.start / 60);
    let seconds = Math.floor(this.start % 60);
    if(seconds < 10) seconds = `0${seconds}`;
    return `${minutes}:${seconds}`;
  }

  get endDisplay(){
    let minutes = Math.floor((this.start + this.length) / 60);
    let seconds = Math.floor((this.start + this.length) % 60);
    if(seconds < 10) seconds = `0${seconds}`;
    return `${minutes}:${seconds}`;
  }

}
