<template>
  <SelectSingle
    :label="label"
    optionsLabel="label"
    optionsKey="deviceId"
    :selectedOption="selectedDevice"
    :options="devices"
    :disabled="!devicesLoaded"
    @single-select="onDeviceChange"
  />
</template>

<script setup>
import { defineProps, computed } from "vue";
import SelectSingle from "../SelectSingle.vue";

const props = defineProps({
  devicesLoaded: Boolean,
  devices: Array,
  selectedDevice: {Object, default: null},
  onDeviceChange: Function,
  label: String,
});

const selectedDeviceText = computed(() => {
  return props.devicesLoaded ? props.selectedDevice.label : "Select a device";
});

function onDeviceChange(device) {
  props.onDeviceChange(device);
}
</script>
