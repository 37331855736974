<template>
  <li class="relative">
    <div class="group aspect-video block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
      <img
        :src="this.preview"
        alt=""
        class="pointer-events-none object-cover group-hover:opacity-75"
      >

      <ScaleLoader
        v-if="video.video_state === 'PROCESSING'"
        class="absolute inset-0 w-full h-full flex items-center justify-center"
        color="#FFA500"
      />
      <a
        class="absolute inset-0 focus:outline-none"
        :href="`/editor/videos/${video.id}`"
      >
        <span class="sr-only">Edit {{video.title}}</span>
      </a>
    </div>
    <p class="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
      {{video.title}}
    </p>
    <p class="pointer-events-none block text-sm font-medium text-gray-500">
      {{video.description}}
    </p>
    <div
      class="inline-block"
    >
      <DeleteButton
        :route="`/editor/videos/${video.id}`"
        title="Delete Video"
        warning="Are you sure you want to delete this custom video?"
      />
    </div>

    <div
      v-if="video.last_error"
      class="text-red-600 text-xs"
    >
      {{ video.last_error }}
    </div>


  </li>

</template>

<script>
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import DeleteButton from "./DeleteButton.vue";
import { XCircleIcon } from '@heroicons/vue/20/solid';

export default {
  components:{
    ScaleLoader,
    XCircleIcon,
    DeleteButton,
  },
  computed:{
    preview(){
      let rendered = this.video.timelines.filter(t => t.video_state === 'DONE');
      if(rendered.length > 0){
        return rendered[0].renderedVideo.preview;
      }
      let processing = this.video.timelines.filter(t => t.video_state === 'PROCESSING');
      if (processing.length > 0) {
        return 'https://via.placeholder.com/300x200.png?text=Rendering...';
      }

      return 'https://via.placeholder.com/300x200.png?text=New+Video';
    }
  },
  props:{
    video: {
      type: Object,
      required: true
    },
  },
}
</script>
