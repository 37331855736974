<template>
  <div class="-mx-4">
    <div class="-mx-2 mb-4">
      <link-secondary
        href="/searches"
      >
        Done
     </link-secondary>
    </div>

    <div class="w-full mb-6 mx-1 p-4 bg-gray-200 sm:rounded-lg">
      <h2 class="text-gray-600 font-semibold text-xl mb-2">
        Landed Positions
      </h2>
      <p
        v-if="positions.length === 0"
        class="text-center text-xl text-gray-400"
      >
        -- No Landed Positions --
      </p>
      <ul class="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
        <li
          v-for="position in sortedPositions"
          :key="position.id"
          class="relative col-span-1 bg-white rounded-lg shadow flex justify-between flex-col"
        >
          <div class="w-full flex items-center justify-between p-6 space-x-6">
            <div class="flex-1 w-full">
              <div class="flex items-center space-x-3">
                <h3 class="text-gray-900 text-sm leading-5 font-medium">
                  {{ position.candidate.name }}
                </h3>
              </div>
              <div>
                <span
                  v-if="position.left_in_guarantee_period"
                  class="items-center px-1"
                >
                  <FontAwesomeIcon
                    :icon="faExclamationTriangle"
                    class="h-4 w-4 text-orange-700 cursor-pointer"
                    title="Left Within Guarantee Period"
                  />
                </span>
                <span
                  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800"
                >
                  Started - {{ (new Date(position.created_at)).toLocaleDateString() }}
                </span>
                <span
                  v-if="!position.in_role"
                  class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-orange-100 text-orange-800"
                >
                  Ended - {{ (new Date(position.updated_at)).toLocaleDateString() }}
                </span>
              </div>
            </div>

            <div
              class="flex absolute top-0 right-0"
            >
              <DeleteButton
                :emits="true"
                @confirm="removeFromPosition(position)"
                :dusk="`remove-from-position-${position.id}`"
                hoverTitle="Remove from Position"
                :disabled="position.in_role === false"
                classNames="p-1 border hover:text-red-500 disabled:text-gray-300 disabled:cursor-not-allowed"
              >
                <FontAwesomeIcon :icon="faPersonCircleMinus" class="h-5 w-5" />
              </DeleteButton>
              <DeleteButton
                :dusk="`delete-position-${position.id}`"
                :emits="true"
                @confirm="deletePosition(position)"
                classNames="p-1 border hover:text-red-500 disabled:text-gray-300 disabled:cursor-not-allowed"
              >
                <FontAwesomeIcon :icon="faTrashCan" class="h-4 w-4" />
              </DeleteButton>
            </div>
          </div>

        </li>
      </ul>
    </div>


    <div class="w-full mb-6 mx-1 p-4 bg-gray-200 sm:rounded-lg">
      <div class="mb-2 -mt-5">
        <SelectSingle
          dusk="availableCandidates"
          :options="availableCandidates"
          @singleSelect="assignCandidate"
          :emitOnly="true"
          placeholder="Assign a Candidate"
          optionsLabel="name"
          searchKey="name"
        />
      </div>

      <h2 class="text-gray-600 font-semibold text-xl mb-2">
        Assigned Candidates
      </h2>
      <p
        v-if="assignedCandidates.length === 0"
        class="text-center text-xl text-gray-400"
      >
        -- No Assigned Candidates --
      </p>
      <ul class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
        <li
          v-for="candidate in sortedAssignedCandidates"
          :key="candidate.id"
          class="search col-span-1 bg-white rounded-lg shadow flex justify-between flex-col"
        >
          <div class="w-full flex items-center justify-between p-6 space-x-6">
            <div class="flex-1 w-full">
              <div class="flex items-center space-x-3">
                <h3 class="text-gray-900 text-sm leading-5 font-medium">
                  {{ candidate.name }}
                </h3>
              </div>
            </div>
          </div>
          <div class="border-t border-gray-200">
            <div class="-mt-px flex border-t">
              <button
                @click="removeCandidate(candidate)"
                :dusk="`remove-candidate-${candidate.id}`"
                class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border-r rounded-bl-lg hover:text-red-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150"
              >
                <FontAwesomeIcon :icon="faMagnifyingGlassMinus" class="h-5 w-5" />
                <span class="ml-3">Remove</span>
              </button>
              <button
                @click="fillPosition(candidate)"
                :dusk="`fill-position-${candidate.id}`"
                class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border-r rounded-bl-lg hover:text-blue-500 focus:outline-none focus:ring-blue focus:border-blue-300 focus:z-10 disabled:cursor-not-allowed disabled:text-gray-300 transition ease-in-out duration-150"
              >
                <FontAwesomeIcon :icon="faPersonCirclePlus" class="h-5 w-5" />
                <span class="ml-3">Fill Position</span>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>

  </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import axios from 'axios';
import LinkSecondary from './LinkSecondary.vue';
import DeleteButton from './DeleteButton.vue';
import SelectSingle from './SelectSingle.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faMagnifyingGlassMinus,
  faPersonCirclePlus,
  faPersonCircleMinus,
  faTrashCan,
  faExclamationTriangle
} from '@fortawesome/pro-regular-svg-icons';

const props = defineProps({
  search: Object,
  candidates: Array,
})

const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

const assignedCandidates = ref(props.search.candidates);
const positions = ref(props.search.positions);

  const availableCandidates = computed(function(){
    let assignedIds = assignedCandidates.value.map( s => s.id );
    let positionsIds = positions.value.map( p => p.candidate.id );
    return props.candidates
      .filter( s => !assignedIds.includes(s.id) )
      .filter( s => !positionsIds.includes(s.id) )
      .sort( (a, b) => a.name.localeCompare(b.name) );
  });

  const sortedAssignedCandidates = computed(function(){
    let positionsIds = positions.value.map( p => p.candidate.id );
    return assignedCandidates.value
      .filter( s => !positionsIds.includes(s.id) )
      .sort( (a, b) =>  a.name.localeCompare(b.name) );
  })

  const sortedPositions = computed(function(){
  console.log(positions.value);
    return positions.value
      .sort( (a, b) => a.created_at.localeCompare(b.created_at) );
  });

  async function assignCandidate(candidate){
    assignedCandidates.value = [...assignedCandidates.value, candidate];

    const {data} = await axios.post(`/searches/${props.search.id}/manage`, {
      assign: candidate.id,
      _token: csrf
    });

    assignedCandidates.value = data.search.candidates;
  };

  async function removeCandidate(candidate){
    assignedCandidates.value = assignedCandidates.value.filter( c => c.id !== candidate.id );

    const {data} = await axios.post(`/searches/${props.search.id}/manage`, {
      remove: candidate.id,
      _token: csrf
    });

    assignedCandidates.value = data.search.candidates;
  };

  async function fillPosition(candidate){
    positions.value = [...positions.value, {candidate, in_role: true}];

    const {data} = await axios.post(`/searches/${props.search.id}/manage`, {
      fill: candidate.id,
      _token: csrf
    });

    positions.value = data.search.positions;
  };

  async function removeFromPosition(position){
    positions.value = positions.value.map( p => {
      if(p.candidate.id === position.candidate.id){
        return {...p, in_role: false};
      }
      return p;
    });

    const {data} = await axios.post(`/searches/${props.search.id}/manage`, {
      unfill: position.id,
      _token: csrf
    });

    positions.value = data.search.positions;
  }

  async function deletePosition(position){
    positions.value = positions.value.filter( p => p.id !== position.id );

    const {data} = await axios.post(`/searches/${props.search.id}/manage`, {
      delete_position: position.id,
      _token: csrf
    });

    positions.value = data.search.positions;
  }

</script>
