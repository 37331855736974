<template>
  <span class="inline-flex rounded-md shadow-sm">
  <a
    :href="href"
    :dusk="dusk"
    type="button"
    class="py-2 px-4 mx-2 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
  >
    <slot></slot>
  </a>
</span>
</template>

<script>
  export default {
    props:{
      dusk:{
        type:String,
        default:''
      },
      href:{
        type:String,
        default:''
      }
    }
  }
</script>
