<template>
  <div class="flex flex-col">
    <div class="relative w-full">
      <input
        :value="minValue"
        @input="updateMin"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        class="absolute pointer-events-none appearance-none opacity-0 z-20 h-2 w-full  cursor-pointer"
      />
      <input
        :value="maxValue"
        @input="updateMax"
        type="range"
        :min="min"
        :max="max"
        :step="step"
        class="absolute pointer-events-none appearance-none opacity-0 z-20 h-2 w-full  cursor-pointer"
      />
      <div class="relative z-10 h-2">
        <div
          class="absolute z-10 left-0 right-0 bottom-0 top-0 rounded-md bg-gray-200"
        ></div>
        <div
          class="absolute z-20 top-0 bottom-0 rounded-md bg-blue-300"
          :style="`right: ${maxThumb}%; left: ${minThumb}%`"
        ></div>
        <div
          class="absolute z-30 w-6 h-6 top-0 left-0 bg-blue-300 rounded-full -mt-2 -ml-1"
          :style="`left: ${minThumb}%`"
        ></div>
        <div
          class="absolute z-30 w-6 h-6 top-0 right-0 bg-blue-300 rounded-full -mt-2 -mr-3"
          :style="`right: ${maxThumb}%`"
        ></div>
      </div>
    </div>
    <div class="flex justify-between items-center py-5">
      <div>
        <input
        :value="minValue"
        @change="updateMin"
        :dusk="minName"
        :name="minName"
        type="number"
        :min="min"
        :max="maxValue - step"
        :step="step"
        class="text-xs border border-gray-200 rounded text-center"
      >
      </div>
      <div>
        <input
        :value="maxValue"
        @change="updateMax"
        :dusk="maxName"
        :name="maxName"
        type="number"
        :min="minValue + step"
        :max="max"
        :step="step"
        class="text-xs border border-gray-200 rounded text-center"
      >
      </div>
    </div>
  </div>
</template>

<style>
input[type=range]{
    -webkit-appearance: none;
    appearance:none;
}
  input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 24px;
    height: 24px;
    opacity: .5;
    border-radius: 50%;
    background: blue;
    -webkit-appearance: none;
  }
  input[type=range]::-moz-range-thumb {
    pointer-events: all;
    width: 100px;
    height: 42px;
    appearance: none;
  }
</style>

<script setup>
import { ref, defineProps, computed, defineEmits } from 'vue';

const props = defineProps({
  minValue: {
    type: Number,
    required: true,
  },
  maxValue: {
    type: Number,
    required: true,
  },
  minName: {
    type: String,
    default: 'min',
  },
  maxName: {
    type: String,
    default: 'max',
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  }
});

const minThumb = computed(() => {
  return  ( ((props.minValue - props.min) / (props.max - props.min)) * 96 );
});

const maxThumb = computed(() => {
  return (100 - (((props.maxValue - props.min) / (props.max - props.min)) * 98) );
});

const emit = defineEmits(['update']);

function updateMin(event) {
  let min = Math.min(event.target.value, parseInt(props.maxValue) - 10000)
  if(min < props.min) {
    min = props.min;
  }
  emit('update', min, props.maxValue);
};

function updateMax(event) {
  let max = Math.max(event.target.value, parseInt(props.minValue) + 10000)
  if(max > props.max) {
    max = props.max;
  }
  emit('update', props.minValue, max)
};
</script>
