<template>

  <div class="mx-2 my-4 text-xs" dusk="message">
    <div
      :class="{
        'flex justify-end': isSender,
        'flex justify-start': !isSender
      }"
    >
      <div
        style="max-width: 60%;"
        :class="{
            'text-right': isSender,
            'text-left': !isSender
          }"
      >
        <div
          style="font-size: 0.6rem;"
          class="text-gray-400 leading-3"
        >
          <img
            v-if="!isSender"
            :src="message.sender.headshot.avatar"
            class="inline-block align-text-bottom mr-2 w-6 h-6 rounded-full bg-gray-700"
          />
          <div class="inline-block">
            {{ message.sender.name }}<br>
            {{ sentOn }}
          </div>
        </div>


        <div
          class="inline-block text-white py-1 px-4 rounded-xl"
          :class="{
            'bg-blue-600 rounded-br-none text-right': isSender,
            'bg-green-600 rounded-bl-none': !isSender,
          }"
        >
          <span v-html="message.body"></span>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { defineProps, ref, computed } from 'vue';

const props = defineProps({
  message: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true
  }
});

const isSender = computed(() => {
  return props.message.sender.uuid === props.user.uuid;
});

const sentOn = computed(() => {
  const sentOn = new Date(`${props.message.created_at} UTC`);
  const now = new Date();
  const sentSameDayNumber = sentOn.getDate() === now.getDate();
  const sentSameMonthNumber = sentOn.getMonth() === now.getMonth();
  const sentThisYear = sentOn.getFullYear() === now.getFullYear();
  const sentToday = sentSameDayNumber && sentSameMonthNumber && sentThisYear;;

  if(sentToday){
    return new Intl.DateTimeFormat(
      'en-US',
      {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    ).format(sentOn);
  } else if(sentThisYear){
    return new Intl.DateTimeFormat(
      'en-US',
      {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    ).format(sentOn);
  } else {
    return new Intl.DateTimeFormat(
      'en-US',
      {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      }
    ).format(sentOn);
  }
});

</script>
