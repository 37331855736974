<template>
  <span class="inline-flex -mt-2 align-bottom">

    <ButtonSecondary
      dusk="public-share-button"
      @click="this.open = true"
    >
      <ShareIcon class="h-5 border-0"/>
    </ButtonSecondary>

    <SimpleModal
      :open="this.open"
      @cancel="this.open = false"
      cancelText="Close"
      :hideConfirm="true"
      :title="'Share ' + model.name"
    >
      <div class="mt-2">

        <div class="mt-4">
          <label for="enable_share" class="block text-sm font-medium text-gray-700">
            Enable Public Sharing
          </label>
          <SimpleToggle
            id="enable_share"
            dusk="enable-share"
            :enabled="this.shareable"
            @toggle="this.share(!this.shareable)"
          />
        </div>

        <p
          v-if="model.share_url"
          class="text-sm text-gray-500"
        >
          Share this {{ model.name }} with others by sending them the link below.
        </p>

        <div
          v-if="this.shareUrl"
          class="mt-4"
        >
          <label for="share_url" class="block text-sm font-medium text-gray-700">
            Share URL
          </label>
          <div class="mt-1 relative">
            <input
              type="text"
              name="share_url"
              id="share_url"
              dusk="share-url"
              class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              :value="this.shareUrl"
              readonly
            >
            <button
              class="-ml-10 absolute right-1 top-1 border p-1 bg-white hover:bg-gray-100 rounded-md"
              @click="this.copyUrl"
            >
              <ClipboardDocumentIcon class="h-5 border-0"/>
            </button>
          </div>
        </div>
      </div>
    </SimpleModal>
  </span>
</template>

<script>
import ButtonSecondary from "./ButtonSecondary.vue";
import SimpleModal from "./SimpleModal.vue";
import SimpleToggle from "./SimpleToggle.vue";
import axios from 'axios';
import { ShareIcon, ClipboardDocumentIcon } from '@heroicons/vue/24/solid'

export default {
  name: "PublicShareButton",
  components: {
    ButtonSecondary,
    SimpleModal,
    ShareIcon,
    SimpleToggle,
    ClipboardDocumentIcon,
  },
  data(){
    return {
      open:false,
      shareable: this.model.share_url ? true : false,
      shareUrl: this.model.share_url,
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    saveUrl: {
      type: String,
      required: true
    }
  },
  methods: {
    async share(boolean) {
      this.shareable = boolean;
      let {data} = await axios.put(this.saveUrl, {
        share: boolean
      });

      this.shareable = data.share_url ? true : false;
      this.shareUrl = data.share_url;
    },

    copyUrl() {
      navigator.clipboard.writeText(this.shareUrl);
    }
  }
}
</script>
