<template>
  <div>
    <div
      :title="this.sourceFile.name"
      class="mt-2 text-xs text-gray-400  whitespace-nowrap text-ellipsis overflow-hidden hidden-for-drag "
    >
      {{ this.sourceFile.name || '&nbsp' }}
    </div>

    <div
      v-if="this.sourceFile.last_error"
      class="text-red-600 text-xs"
    >
      {{ this.sourceFile.last_error }}
    </div>

    <div class="relative">
      <div
        v-if="this.sourceFile.type === 'html'"
        class="bg-black group block w-full overflow-hidden rounded-lg"
      >
        <TitleSlide :sourceFile="this.sourceFile" />
      </div>
      <div
        v-else
        class="group aspect-video block w-full overflow-hidden rounded-lg"
      >
        <img
          :src="this.sourceFile.preview_url || 'https://via.placeholder.com/640x480.png?text=Rendering...'"
          alt=""
          class="pointer-events-none object-cover group-hover:opacity-75"
        >
        <ScaleLoader
          v-if="!this.sourceFile.preview_url"
          class="absolute inset-0 w-full h-full flex items-center justify-center"
          color="#FFA500"
        />
      </div>

    </div>

    <div class="relative hidden-for-drag text-left">
      <span class="isolate inline-flex rounded-md shadow-sm relative -top-6">
        <button
          v-if="this.sourceFile.type !== 'html'"
          type="button"
          title="Preview"
          class="relative inline-flex items-center rounded-bl-md bg-white px-1 py-1 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          @click="this.previewModalOpen = true"
        >
          <span class="sr-only">Preview</span>
          <EyeIcon class="h-4 w-4" aria-hidden="true" />
        </button>
        <button
          type="button"
          title="Create Subclip"
          class="relative -ml-px inline-flex items-center bg-white px-1 py-1 text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          :class="{'rounded-bl-md': this.sourceFile.type === 'html'}"
          @click="this.editModalOpen = true"
        >
          <span class="sr-only">Edit</span>
          <PencilSquareIcon
            v-if="this.sourceFile.type === 'html'"
            class="h-4 w-4"
            aria-hidden="true"
          />
          <ScissorsIcon
            v-else
            class="h-4 w-4"
            aria-hidden="true"
          />
        </button>
        <button
          type="button"
          title="Delete Source File"
          class="relative -ml-px inline-flex items-center rounded-tr-md bg-white px-1 py-1 text-red-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          @click="this.$emit('remove')"
        >
          <span class="sr-only">Delete</span>
          <XCircleIcon class="h-4 w-4" aria-hidden="true" />
        </button>
      </span>
    </div>

    <SimpleModal
      :open="this.previewModalOpen"
      :hideCancel="true"
      :hideConfirm="true"
      :fullWidth="true"
      @cancel="this.previewModalOpen = false"
    >
      <template v-slot:content>
        <VideoPreview
          :sourceFile="this.sourceFile"
        />
      </template>
    </SimpleModal>


    <SimpleModal
      :open="this.editModalOpen"
      :hideCancel="true"
      :hideConfirm="true"
      :fullWidth="true"
      @cancel="this.editModalOpen = false"
    >
      <template v-slot:content>
        <TitleSlideEditor
          v-if="this.sourceFile.type === 'html'"
          :sourceFile="this.sourceFile"
          @save="this.doneEditing"
          @cancel="this.editModalOpen = false"
        />
        <SourceFileEditor
          v-else
          :sourceFile="this.sourceFile"
          :trimSourceFileUrl="this.trimSourceFileUrl"
          @done="this.doneEditing"
        />
      </template>
    </SimpleModal>
  </div>

</template>

<script>
  import SimpleModal from '../SimpleModal.vue'
  import SourceFileEditor from './SourceFileEditor.vue'
  import TitleSlideEditor from './TitleSlideEditor.vue'
  import TitleSlide from './TitleSlide.vue'
  import VideoPreview from '../VideoPreview.vue'
  import { XCircleIcon, ScissorsIcon, EyeIcon, PencilSquareIcon } from '@heroicons/vue/24/solid'
  import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

  export default {
    components: {
      XCircleIcon,
      ScissorsIcon,
      PencilSquareIcon,
      EyeIcon,
      SimpleModal,
      SourceFileEditor,
      TitleSlideEditor,
      TitleSlide,
      ScaleLoader,
      VideoPreview,
    },
    props: {
      sourceFile: {
        type: Object,
        required: true
      },
      trimSourceFileUrl: {
        type: String,
        required: true
      },
    },
    data(){
      return {
        editModalOpen: false,
        previewModalOpen: false,
      }
    },
    methods: {
      doneEditing(sourceFile){
        this.editModalOpen = false;
        if(this.sourceFile.type === 'html'){
          this.$emit('updateTitleSlide', sourceFile);
        } else {
          this.$emit('newSourceFile', sourceFile);
        }
      },
    },
  }
</script>

